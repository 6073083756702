import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminClients = ({ token }) => {
  const [clients, setClients] = useState([]);

  const fetchClients = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/admin/clients', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const clientsWithDetails = await Promise.all(res.data.map(async (client) => {
        try {
          const qrRes = await axios.get(`https://api.sagabot.dtcx.com.br/api/bot/qrcode/${client.clientId}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          return { ...client, qrCode: qrRes.data.qrCode, status: client.status || 'Unknown' };
        } catch (error) {
          console.error(`Error fetching QR Code for client ${client.clientId}:`, error);
          return { ...client, qrCode: 'QR Code not available', status: 'Unknown' };
        }
      }));

      setClients(clientsWithDetails);
    } catch (err) {
      console.error('Error fetching clients:', err);
    }
  };

  useEffect(() => {
    fetchClients();
  }, [token]);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Clientes Existentes</h1>
      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            <th className="py-2">ID do Cliente</th>
            <th className="py-2">QR Code</th>
            <th className="py-2">Status</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => (
            <tr key={client.clientId} className="border-t">
              <td className="py-2 text-center">{client.clientId}</td>
              <td className="py-2 text-center">
                {client.qrCode ? <img src={client.qrCode} alt="QRCode" className="w-24 h-24 mx-auto" /> : 'QR Code not available'}
              </td>
              <td className={`py-2 text-center ${client.status === 'Online' ? 'text-green-500' : 'text-red-500'}`}>
                {client.status}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminClients;
