import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useOutletContext } from 'react-router-dom';

// Defina o elemento raiz para o modal
Modal.setAppElement('#root');

const AdminPanel = () => {
  const { token } = useOutletContext();
  const [clientId, setClientId] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('client');
  const [clients, setClients] = useState([]);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [adminData, setAdminData] = useState(null);

  const fetchAdminData = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/auth/admin-data', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setAdminData(res.data);
    } catch (err) {
      console.error('Error fetching admin data:', err);
    }
  };

  const handleAddClient = async () => {
    try {
      await axios.post(
        'https://api.sagabot.dtcx.com.br/api/admin/add-client',
        { clientId, password, role },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      setMessage('Cliente adicionado com sucesso');
      setMessageType('success');
      setModalIsOpen(true);  // Abra o modal
      fetchClients();
    } catch (err) {
      console.error('Error adding client:', err);
      setMessage('Erro ao adicionar cliente');
      setMessageType('error');
    }
  };

  const fetchClients = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/admin/clients', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const clientsWithDetails = await Promise.all(res.data.map(async (client) => {
        try {
          const qrRes = await axios.get(`https://api.sagabot.dtcx.com.br/api/bot/qrcode/${client.clientId}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          return { ...client, qrCode: qrRes.data.qrCode, status: client.status || 'Unknown' };
        } catch (error) {
          console.error(`Error fetching QR Code for client ${client.clientId}:`, error);
          return { ...client, qrCode: 'QR Code not available', status: 'Unknown' };
        }
      }));

      setClients(clientsWithDetails);
    } catch (err) {
      console.error('Error fetching clients:', err);
      setMessage('Erro ao buscar clientes');
      setMessageType('error');
    }
  };

  useEffect(() => {
    fetchClients();
    fetchAdminData();
  }, [token]);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Painel Administrativo</h1>
      {message && (
        <div className={`mb-4 p-4 rounded ${messageType === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
          {message}
        </div>
      )}
      <form className="mb-6">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">ID do Cliente:</label>
          <input
            type="text"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Senha:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Papel:</label>
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="client">Client</option>
            <option value="admin">Admin</option>
          </select>
        </div>
        <button
          type="button"
          onClick={handleAddClient}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Adicionar Cliente
        </button>
      </form>
      <h2 className="text-xl font-bold mb-4">Clientes Existentes</h2>
      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            <th className="py-2">ID do Cliente</th>
            <th className="py-2">QR Code</th>
            <th className="py-2">Status</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => (
            <tr key={client.clientId} className="border-t">
              <td className="py-2 text-center">{client.clientId}</td>
              <td className="py-2 text-center">
                {client.qrCode ? <img src={client.qrCode} alt="QRCode" className="w-24 h-24 mx-auto" /> : 'QRCode not available'}
              </td>
              <td className={`py-2 text-center ${client.status === 'Online' ? 'text-green-500' : 'text-red-500'}`}>
                {client.status}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Client Added"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Sucesso!</h2>
        <div>Cliente adicionado com sucesso.</div>
        <button onClick={() => setModalIsOpen(false)}>Fechar</button>
      </Modal>
    </div>
  );
};

export default AdminPanel;
