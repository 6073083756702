import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

// Estilos para o modal
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '500px'
  },
};

Modal.setAppElement('#root');

const AdminSettings = ({ token }) => {
  const [clientId, setClientId] = useState('');
  const [password, setPassword] = useState('');
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [options, setOptions] = useState(['']);
  const [responses, setResponses] = useState(['']);
  const [unrecognizedMessage, setUnrecognizedMessage] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleResponseChange = (index, value) => {
    const newResponses = [...responses];
    newResponses[index] = value;
    setResponses(newResponses);
  };

  const addOptionResponse = () => {
    setOptions([...options, '']);
    setResponses([...responses, '']);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const responsesObject = options.reduce((acc, option, index) => {
      if (option && responses[index]) {
        acc[option] = responses[index];
      }
      return acc;
    }, {});

    try {
      await axios.post(
        `https://api.sagabot.dtcx.com.br/api/bot/configure`,
        {
          clientId,
          welcomeMessage,
          options,
          responses: responsesObject,
          unrecognizedMessage,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      openModal('Configuração do bot salva com sucesso');
    } catch (error) {
      console.error('Erro ao salvar a configuração do bot', error);
      openModal('Erro ao salvar a configuração do bot');
    }
  };

  const handleAddClient = async () => {
    try {
      await axios.post(
        'https://api.sagabot.dtcx.com.br/api/admin/add-client',
        { clientId, password, role: 'client' },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      openModal('Cliente adicionado com sucesso');
    } catch (err) {
      console.error('Erro ao adicionar cliente:', err);
      openModal('Erro ao adicionar cliente');
    }
  };

  const openModal = (message) => {
    setModalMessage(message);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalMessage('');
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const res = await axios.get(`https://api.sagabot.dtcx.com.br/api/bot/configure/${clientId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const config = res.data;
        setWelcomeMessage(config.welcomeMessage || '');
        setOptions(config.options || ['']);
        const responsesArray = Array.isArray(config.responses) ? config.responses : Object.values(config.responses || {});
        setResponses(responsesArray);
        setUnrecognizedMessage(config.unrecognizedMessage || '');
      } catch (error) {
        console.error('Erro ao carregar a configuração do bot', error);
      }
    };

    if (clientId) {
      fetchConfig();
    }
  }, [clientId, token]);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Configurações do Bot</h1>
      <form onSubmit={handleSubmit} className="mb-6">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">ID do Cliente:</label>
          <input
            type="text"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Senha:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <button
          type="button"
          onClick={handleAddClient}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-6"
        >
          Adicionar Cliente
        </button>
        <div className="mb-4 mt-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">Mensagem de Boas-vindas:</label>
          <input
            type="text"
            value={welcomeMessage}
            onChange={(e) => setWelcomeMessage(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        {options.map((option, index) => (
          <div key={index} className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Opção {index + 1}:</label>
            <input
              type="text"
              value={option}
              onChange={(e) => handleOptionChange(index, e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <label className="block text-gray-700 text-sm font-bold mb-2">Resposta:</label>
            <input
              type="text"
              value={responses[index]}
              onChange={(e) => handleResponseChange(index, e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        ))}
        <button
          type="button"
          onClick={addOptionResponse}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-4"
        >
          Adicionar Opção/Resposta
        </button>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Mensagem não reconhecida:</label>
          <input
            type="text"
            value={unrecognizedMessage}
            onChange={(e) => setUnrecognizedMessage(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <button
          type="submit"
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Salvar Configuração
        </button>
      </form>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Mensagem"
      >
        <h2 className="text-xl font-bold mb-4">Mensagem</h2>
        <p>{modalMessage}</p>
        <button onClick={closeModal} className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Fechar
        </button>
      </Modal>
    </div>
  );
};

export default AdminSettings;
