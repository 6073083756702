import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ setToken }) => {
  const [clientId, setClientId] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!clientId || !password) {
      setError("Por favor, preencha todos os campos.");
      return;
    }
    
    try {
      const res = await axios.post('https://api.sagabot.dtcx.com.br/api/auth/login', { clientId, password });
      localStorage.setItem('token', res.data.token);
      setToken(res.data.token);
      const decodedToken = JSON.parse(atob(res.data.token.split('.')[1]));
      const role = decodedToken.user.role;
      
      setTimeout(() => {
        if (role === 'admin') {
          navigate('/admin/dashboard');
        } else if (role === 'admin_empresa') {
          navigate('/admin-empresa/dashboard');
        } else {
          navigate('/client/dashboard');
        }
      }, 500); // Pequeno atraso de 500ms para garantir o armazenamento do token
    } catch (err) {
      console.error('Login falhou', err);
      setError("Falha no login, verifique suas credenciais e tente novamente.");
    }
  };

  return (
    <div className="min-h-screen bg-cover bg-center" style={{ backgroundImage: "url('https://portal.datacampos.com/files/system/sigin-background-image.jpg')" }}>
      <div className="flex items-center justify-center min-h-screen">
        <form onSubmit={handleSubmit} className="p-8 bg-white rounded-lg shadow-lg max-w-lg w-full">
          <div className="flex justify-center mb-6">
            <img src="https://portal.datacampos.com/files/system/_file6249ea4e33263-site-logo.png" alt="Data Campos Logo" className="w-48 h-auto"/>
          </div>
          <div>
            <label htmlFor="clientId" className="block text-sm font-medium text-gray-700">ID do Cliente:</label>
            <input type="text" id="clientId" value={clientId} onChange={(e) => setClientId(e.target.value)} className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
          </div>
          <div className="mt-4">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Senha:</label>
            <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
          </div>
          {error && <p className="mt-4 text-sm text-red-500">{error}</p>}
          <div className="mt-6">
            <button type="submit" className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Login</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
