import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';

const ClientSupport = () => {
  const { token } = useOutletContext();
  const [faqs, setFaqs] = useState([]);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [keywords, setKeywords] = useState('');
  const [error, setError] = useState('');
  const [editingFaqId, setEditingFaqId] = useState(null);

  const fetchFaqs = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/client/faq', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFaqs(res.data);
    } catch (err) {
      console.error('Error fetching FAQs:', err);
      setError('Error fetching FAQs: ' + err.message);
    }
  };

  const handleAddOrUpdateFaq = async () => {
    try {
      if (editingFaqId) {
        // Edit FAQ
        await axios.put(
          `https://api.sagabot.dtcx.com.br/api/client/faq/${editingFaqId}`,
          { question, answer, keywords: keywords.split(',') },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        // Add new FAQ
        await axios.post(
          'https://api.sagabot.dtcx.com.br/api/client/faq/add',
          { question, answer, keywords: keywords.split(',') },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
      fetchFaqs();
      setQuestion('');
      setAnswer('');
      setKeywords('');
      setEditingFaqId(null);
    } catch (err) {
      console.error('Error adding/updating FAQ:', err);
      setError('Error adding/updating FAQ: ' + err.message);
    }
  };

  const handleEditFaq = (faq) => {
    setQuestion(faq.question);
    setAnswer(faq.answer);
    setKeywords(faq.keywords.join(','));
    setEditingFaqId(faq._id);
  };

  const handleDeleteFaq = async (id) => {
    try {
      await axios.delete(`https://api.sagabot.dtcx.com.br/api/client/faq/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchFaqs();
    } catch (err) {
      console.error('Error deleting FAQ:', err);
      setError('Error deleting FAQ: ' + err.message);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, [token]);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Gerenciamento de FAQs</h1>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-4">{editingFaqId ? 'Editar FAQ' : 'Adicionar Nova FAQ'}</h2>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Pergunta:</label>
          <input
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Resposta:</label>
          <input
            type="text"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Palavras-chave (separadas por vírgula):</label>
          <input
            type="text"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <button
          onClick={handleAddOrUpdateFaq}
          className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
        >
          {editingFaqId ? 'Atualizar FAQ' : 'Adicionar FAQ'}
        </button>
      </div>
      <h2 className="text-xl font-bold mb-4">FAQs Existentes</h2>
      {faqs.length > 0 ? (
        faqs.map((faq) => (
          <div key={faq._id} className="mb-4">
            <h3 className="text-lg font-bold">{faq.question}</h3>
            <p>{faq.answer}</p>
            <p><strong>Palavras-chave:</strong> {faq.keywords.join(', ')}</p>
            <button
              onClick={() => handleEditFaq(faq)}
              className="bg-yellow-500 text-white px-4 py-2 rounded-md mt-2 mr-2"
            >
              Editar
            </button>
            <button
              onClick={() => handleDeleteFaq(faq._id)}
              className="bg-red-500 text-white px-4 py-2 rounded-md mt-2"
            >
              Remover
            </button>
          </div>
        ))
      ) : (
        <p>Nenhuma FAQ encontrada.</p>
      )}
    </div>
  );
};

export default ClientSupport;
