import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminSupport = ({ token }) => {
  const [faqs, setFaqs] = useState([]);
  const [newFaq, setNewFaq] = useState({ question: '', answer: '', keywords: '', relatedQuestions: [] });
  const [editingFaq, setEditingFaq] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    fetchFaqs();
  }, []);

  const fetchFaqs = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/support', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setFaqs(res.data);
    } catch (err) {
      console.error('Error fetching FAQs:', err);
      setError('Error fetching FAQs: ' + err.message);
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleAddFaq = async () => {
    try {
      await axios.post(
        'https://api.sagabot.dtcx.com.br/api/support/add',
        {
          ...newFaq,
          keywords: newFaq.keywords.split(',').map((word) => word.trim())
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setSuccess('FAQ adicionada com sucesso');
      fetchFaqs();
      setNewFaq({ question: '', answer: '', keywords: '', relatedQuestions: [] });
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      console.error('Error adding FAQ:', err);
      setError('Error adding FAQ: ' + err.message);
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleEditFaq = async () => {
    try {
        const keywords = Array.isArray(editingFaq.keywords)
            ? editingFaq.keywords.join(', ')
            : editingFaq.keywords;

        await axios.put(
            `https://api.sagabot.dtcx.com.br/api/support/${editingFaq._id}`,
            {
                ...editingFaq,
                keywords: typeof keywords === 'string' ? keywords.split(',').map((word) => word.trim()) : []
            },
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        );
        setSuccess('FAQ editada com sucesso');
        fetchFaqs();
        setEditingFaq(null);
        setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
        console.error('Error editing FAQ:', err);
        setError('Error editing FAQ: ' + err.message);
        setTimeout(() => setError(null), 3000);
    }
};


  const handleDeleteFaq = async (id) => {
    try {
      await axios.delete(`https://api.sagabot.dtcx.com.br/api/support/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSuccess('FAQ removida com sucesso');
      fetchFaqs();
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      console.error('Error deleting FAQ:', err);
      setError('Error deleting FAQ: ' + err.message);
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (editingFaq) {
      setEditingFaq((prev) => ({ ...prev, [name]: value }));
    } else {
      setNewFaq((prev) => ({ ...prev, [name]: value }));
    }
  };

  const startEditFaq = (faq) => {
    setEditingFaq(faq);
  };

  const cancelEdit = () => {
    setEditingFaq(null);
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-center">Gerenciamento de FAQs</h2>
      {error && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white text-black p-6 rounded shadow-lg max-w-sm w-full">
            <p>{error}</p>
            <div className="flex justify-center mt-4">
              <button onClick={() => setError(null)} className="bg-red-500 text-white px-4 py-2 rounded">
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}
      {success && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white text-black p-6 rounded shadow-lg max-w-sm w-full">
            <p>{success}</p>
            <div className="flex justify-center mt-4">
              <button onClick={() => setSuccess(null)} className="bg-green-500 text-white px-4 py-2 rounded">
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="mb-6">
        <h3 className="text-lg font-bold mb-2">{editingFaq ? 'Editar FAQ' : 'Adicionar Nova FAQ'}</h3>
        <input
          type="text"
          name="question"
          value={editingFaq ? editingFaq.question : newFaq.question}
          onChange={handleChange}
          placeholder="Pergunta"
          className="w-full mb-2 p-2 border rounded"
        />
        <textarea
          name="answer"
          value={editingFaq ? editingFaq.answer : newFaq.answer}
          onChange={handleChange}
          placeholder="Resposta"
          className="w-full mb-2 p-2 border rounded"
        />
        <input
          type="text"
          name="keywords"
          value={editingFaq ? editingFaq.keywords : newFaq.keywords}
          onChange={handleChange}
          placeholder="Palavras-chave (separadas por vírgula)"
          className="w-full mb-2 p-2 border rounded"
        />
        {editingFaq ? (
          <>
            <button
              onClick={handleEditFaq}
              className="w-full bg-blue-500 text-white px-4 py-2 rounded-md mb-2"
            >
              Editar FAQ
            </button>
            <button
              onClick={cancelEdit}
              className="w-full bg-gray-500 text-white px-4 py-2 rounded-md"
            >
              Cancelar
            </button>
          </>
        ) : (
          <button
            onClick={handleAddFaq}
            className="w-full bg-blue-500 text-white px-4 py-2 rounded-md"
          >
            Adicionar FAQ
          </button>
        )}
      </div>
      <div>
        <h3 className="text-lg font-bold mb-2">FAQs Existentes</h3>
        <ul>
          {faqs.map((faq) => (
            <li key={faq._id} className="mb-2 bg-white p-4 rounded-lg shadow-md">
              <h4 className="font-bold">{faq.question}</h4>
              <p>{faq.answer}</p>
              <p><strong>Palavras-chave:</strong> {faq.keywords.join(', ')}</p>
              <button
                onClick={() => startEditFaq(faq)}
                className="mr-2 bg-yellow-500 text-white px-4 py-2 rounded-md"
              >
                Editar
              </button>
              <button
                onClick={() => handleDeleteFaq(faq._id)}
                className="bg-red-500 text-white px-4 py-2 rounded-md"
              >
                Remover
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdminSupport;
