import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaBars, FaTimes } from 'react-icons/fa';

const AdminSidebarLayout = ({ token }) => {
  const [role, setRole] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      setRole(decodedToken.user.role);
    }
  }, [token]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const renderSidebarOptions = () => {
    if (role === 'admin_empresa') {
      return (
        <ul className="space-y-2">
          <li>
            <Link to="/admin/dashboard" className="block py-2 px-3 rounded hover:bg-gray-700">
              Dashboard
            </Link>
          </li>
          <li>
            <Link to="/admin/add-client" className="block py-2 px-3 rounded hover:bg-gray-700">
              Adicionar Cliente
            </Link>
          </li>
          <li>
            <Link to="/admin/bot-config" className="block py-2 px-3 rounded hover:bg-gray-700">
              Configuração do BOT
            </Link>
          </li>
          <li>
            <Link to="/admin/support" className="block py-2 px-3 rounded hover:bg-gray-700">
              Configurar FAQ
            </Link>
          </li>
        </ul>
      );
    } else if (role === 'admin') {
      return (
        <ul className="space-y-2">
          <li>
            <Link to="/admin/dashboard" className="block py-2 px-3 rounded hover:bg-gray-700">
              Dashboard
            </Link>
          </li>
          <li>
            <Link to="/admin/add-client" className="block py-2 px-3 rounded hover:bg-gray-700">
              Adicionar Cliente
            </Link>
          </li>
          <li>
            <Link to="/admin/clients" className="block py-2 px-3 rounded hover:bg-gray-700">
              Clientes Existentes
            </Link>
          </li>
          <li>
            <Link to="/admin/bot-config" className="block py-2 px-3 rounded hover:bg-gray-700">
              Configuração do BOT
            </Link>
          </li>
          <li>
            <Link to="/admin/support" className="block py-2 px-3 rounded hover:bg-gray-700">
              Configurar FAQ
            </Link>
          </li>
          <li>
            <Link to="/admin/company-management" className="block py-2 px-3 rounded hover:bg-gray-700">
              Gerenciamento de Empresas
            </Link>
          </li>
          <li>
            <Link to="/admin/group-management" className="block py-2 px-3 rounded hover:bg-gray-700">
              Gerenciamento de Grupos
            </Link>
          </li>
          <li>
            <Link to="/admin/user-management" className="block py-2 px-3 rounded hover:bg-gray-700">
              Gerenciamento de Usuários
            </Link>
          </li>
        </ul>
      );
    }
    return null;
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <div className={`w-64 bg-gray-900 text-white p-5 flex flex-col justify-between h-full fixed transition-transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}>
        <div className="flex justify-between items-center mb-5">
          <h1 className="text-lg font-semibold">Admin</h1>
          <button onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="text-white focus:outline-none">
            {isSidebarOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
        <nav className="flex-grow">
          {renderSidebarOptions()}
        </nav>
        <button onClick={handleLogout} className="mt-10 w-full bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
          Sair
        </button>
      </div>
      <div className={`flex-1 ${isSidebarOpen ? 'ml-64' : 'ml-0'} p-10 overflow-y-auto bg-gray-100 transition-all`}>
        <Outlet context={{ token }} />
      </div>
    </div>
  );
};

export default AdminSidebarLayout;
