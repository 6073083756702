import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
} from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const Analytics = () => {
  const { clientData, token } = useOutletContext();
  const [stats, setStats] = useState(null);
  const [error, setError] = useState(null);

  const fetchStats = async () => {
    if (clientData && clientData.clientId) {
      try {
        const res = await axios.get(`https://api.sagabot.dtcx.com.br/api/bot/stats/${clientData.clientId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setStats(res.data);
      } catch (err) {
        console.error('Error fetching stats:', err);
        setError('Error fetching stats: ' + err.message);
      }
    }
  };

  useEffect(() => {
    fetchStats();
  }, [clientData, token]);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  const messageTypeData = stats ? {
    labels: ['Mensagens de Usuário', 'Mensagens do Bot'],
    datasets: [
      {
        label: 'Tipos de Mensagem',
        data: [stats.messageTypeCount.fromUser, stats.messageTypeCount.fromBot],
        backgroundColor: ['rgba(255,99,132,0.6)', 'rgba(54,162,235,0.6)'],
        borderColor: ['rgba(255,99,132,1)', 'rgba(54,162,235,1)'],
        borderWidth: 1,
      }
    ]
  } : null;

  const messageCountData = stats ? {
    labels: Object.keys(stats.messageCountByDay),
    datasets: [
      {
        label: 'Mensagens por Dia',
        data: Object.values(stats.messageCountByDay),
        backgroundColor: 'rgba(75,192,192,0.6)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1
      }
    ]
  } : null;

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-6 text-center">Estatísticas de Uso</h2>
      <div className="flex flex-col lg:flex-row lg:justify-around">
        {stats && (
          <>
            <div className="w-full lg:w-1/2 mb-6 lg:mb-0 p-4">
              <h3 className="text-xl font-bold mb-4 text-center">Tipos de Mensagem</h3>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <Pie data={messageTypeData} options={{ responsive: true, maintainAspectRatio: false }} />
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-4">
              <h3 className="text-xl font-bold mb-4 text-center">Mensagens por Dia</h3>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <Bar data={messageCountData} options={{ responsive: true, maintainAspectRatio: false }} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Analytics;
