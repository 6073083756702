import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import ConfirmModal from './ConfirmModal';

const AdminEmpresaUsers = () => {
  const { token } = useOutletContext();
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const fetchUsers = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/admin-empresa/users', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const usersWithDetails = await Promise.all(
        res.data.map(async (user) => {
          try {
            const qrRes = await axios.get(`https://api.sagabot.dtcx.com.br/api/bot/qrcode/${user.clientId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            const statusRes = await axios.get(`https://api.sagabot.dtcx.com.br/api/bot/status/${user.clientId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            return { ...user, qrCode: qrRes.data.qrCode, status: statusRes.data.status };
          } catch (error) {
            console.error(`Error fetching details for user ${user.clientId}:`, error);
            return { ...user, qrCode: 'QR Code not available', status: 'Unknown' };
          }
        })
      );

      setUsers(usersWithDetails);
    } catch (err) {
      console.error('Error fetching users:', err);
    }
  };

  const handleDeleteUser = async (clientId) => {
    try {
      await axios.delete(`https://api.sagabot.dtcx.com.br/api/admin-empresa/client/${clientId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(users.filter((user) => user.clientId !== clientId));
      closeModal();
    } catch (err) {
      console.error('Error deleting user:', err);
    }
  };

  const openModal = (clientId) => {
    setUserToDelete(clientId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setUserToDelete(null);
  };

  useEffect(() => {
    fetchUsers();
  }, [token]);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Usuários Existentes</h1>
      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            <th className="py-2">ID do Usuário</th>
            <th className="py-2">QR Code</th>
            <th className="py-2">Status</th>
            <th className="py-2">Ações</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.clientId} className="border-t">
              <td className="py-2 text-center">{user.clientId}</td>
              <td className="py-2 text-center">
                {user.qrCode ? (
                  <img src={user.qrCode} alt="QRCode" className="w-24 h-24 mx-auto" />
                ) : (
                  'QR Code not available'
                )}
              </td>
              <td className={`py-2 text-center ${user.status === 'Online' ? 'text-green-500' : 'text-red-500'}`}>
                {user.status}
              </td>
              <td className="py-2 text-center">
                <button
                  onClick={() => openModal(user.clientId)}
                  className="bg-red-500 text-white px-4 py-2 rounded"
                >
                  Remover
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <ConfirmModal
        isOpen={isModalOpen}
        title="Remover Usuário"
        message={`Tem certeza de que deseja remover o usuário ${userToDelete}?`}
        onConfirm={() => handleDeleteUser(userToDelete)}
        onCancel={closeModal}
      />
    </div>
  );
};

export default AdminEmpresaUsers;
