import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';

const SidebarLayout = ({ token }) => {
  const [clientData, setClientData] = useState(null);
  const [botStatus, setBotStatus] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchClientData = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/auth/client-data', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setClientData(res.data);
    } catch (err) {
      console.error('Error fetching client data:', err);
      setError('Error fetching client data: ' + err.message);
    }
  };

  const fetchQRCode = async (clientId) => {
    try {
      const res = await axios.get(`https://api.sagabot.dtcx.com.br/api/bot/qrcode/${clientId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setClientData(prevState => ({ ...prevState, qrCode: res.data.qrCode }));
    } catch (err) {
      console.error('Error fetching QR Code:', err);
      setError('Error fetching QR Code: ' + err.message);
    }
  };

  const fetchBotStatus = async (clientId) => {
    try {
      const res = await axios.get(`https://api.sagabot.dtcx.com.br/api/bot/status/${clientId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setBotStatus(res.data.status);
    } catch (err) {
      console.error('Error fetching bot status:', err);
      setError('Error fetching bot status: ' + err.message);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  useEffect(() => {
    fetchClientData();
  }, [token]);

  useEffect(() => {
    if (clientData && clientData.clientId) {
      fetchQRCode(clientData.clientId);
      fetchBotStatus(clientData.clientId);

      // Atualiza o QR Code periodicamente
      const intervalId = setInterval(() => {
        fetchQRCode(clientData.clientId);
      }, 300000); // 5 minutos

      return () => clearInterval(intervalId); // Limpa o intervalo ao desmontar o componente
    }
  }, [clientData?.clientId, token]);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="flex h-screen overflow-hidden">
      <div className="w-64 bg-gray-900 text-white p-5 flex flex-col justify-between h-full fixed">
        <div className="flex-grow">
          <h1 className="text-lg font-semibold">Olá, {clientData?.clientId}</h1>
          <p className="mt-3">Status: <span className={`font-semibold ${botStatus === 'Online' ? 'text-green-300' : 'text-red-400'}`}>{botStatus}</span></p>
          <nav className="mt-5">
            <ul className="space-y-2">
              <li>
                <Link to="/client/dashboard" className="block py-2 px-3 rounded hover:bg-gray-700">
                  Dashboard
                </Link>
              </li>
              <li>
                <Link to="/client/config" className="block py-2 px-3 rounded hover:bg-gray-700">
                  Configurações do Bot
                </Link>
              </li>
              <li>
                <Link to="/client/analytics" className="block py-2 px-3 rounded hover:bg-gray-700">
                  Análises
                </Link>
              </li>
              <li>
                <Link to="/client/support" className="block py-2 px-3 rounded hover:bg-gray-700">
                  Configurar FAQ
                </Link>
              </li>
              <li>
                <a href="https://wa.me/5522998516952" target="_blank" rel="noopener noreferrer" className="block py-2 px-3 rounded hover:bg-gray-700">
                  Suporte
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <button onClick={handleLogout} className="mt-10 w-full bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
          Sair
        </button>
      </div>
      <div className="flex-1 ml-64 p-10 overflow-y-auto bg-gray-100">
        <Outlet context={{ clientData, botStatus, token }} />
      </div>
    </div>
  );
};

export default SidebarLayout;
