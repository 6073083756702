import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import ConfirmModal from './ConfirmModal'; // Importe o modal de confirmação

const AdminEmpresaAddUser = () => {
  const { token } = useOutletContext();
  const [clientId, setClientId] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('client');
  const [grupos, setGrupos] = useState([]);
  const [grupoId, setGrupoId] = useState('');
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchGrupos = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/admin-empresa/grupos', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGrupos(res.data);
      setGrupoId(res.data[0]?.id || '');
    } catch (err) {
      console.error('Erro ao buscar grupos:', err);
      setError('Erro ao buscar grupos: ' + err.message);
    }
  };

  const handleAddUser = async () => {
    try {
      await axios.post(
        'https://api.sagabot.dtcx.com.br/api/admin-empresa/add-user',
        { clientId, password, role, grupoId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setClientId('');
      setPassword('');
      setRole('client');
      setGrupoId('');
      setIsModalOpen(true); // Abre o modal de confirmação
    } catch (err) {
      console.error('Erro ao adicionar usuário:', err);
      setError('Erro ao adicionar usuário: ' + err.message);
    }
  };

  useEffect(() => {
    fetchGrupos();
  }, [token]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Adicionar Usuário</h1>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Client ID:</label>
        <input
          type="text"
          value={clientId}
          onChange={(e) => setClientId(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Senha:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Papel:</label>
        <select
          value={role}
          onChange={(e) => setRole(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="client">Client</option>
          <option value="admin">Admin</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Grupo:</label>
        <select
          value={grupoId}
          onChange={(e) => setGrupoId(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          {grupos.map((grupo) => (
            <option key={grupo.id} value={grupo.id}>
              {grupo.nome}
            </option>
          ))}
        </select>
      </div>
      <button
        onClick={handleAddUser}
        className="bg-green-500 text-white font-bold py-2 px-4 rounded"
      >
        Adicionar Usuário
      </button>

      <ConfirmModal
        isOpen={isModalOpen}
        title="Usuário Adicionado"
        message="O usuário foi adicionado com sucesso!"
        onConfirm={closeModal}
        onCancel={closeModal}
      />
    </div>
  );
};

export default AdminEmpresaAddUser;
