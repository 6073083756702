import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';

const Modal = ({ message, onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300">
    <div className="bg-white p-6 rounded shadow-lg transform transition-transform duration-300 scale-95">
      <p>{message}</p>
      <div className="flex justify-center mt-4">
        <button
          onClick={onClose}
          className="bg-blue-500 text-white px-4 py-2 rounded shadow-md hover:bg-blue-600 transition duration-200"
        >
          Fechar
        </button>
      </div>
    </div>
  </div>
);

const BotConfig = ({ token }) => {
  const { clientData } = useOutletContext();
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [options, setOptions] = useState(['']);
  const [responses, setResponses] = useState(['']);
  const [unrecognizedMessage, setUnrecognizedMessage] = useState('');
  const [askQuestionMessage, setAskQuestionMessage] = useState('');
  const [closeChatMessage, setCloseChatMessage] = useState('');
  const [closeChatTimeout, setCloseChatTimeout] = useState(10); // default to 10 minutes
  const [qrCode, setQrCode] = useState('');
  const [loadingQrCode, setLoadingQrCode] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const res = await axios.get(`https://api.sagabot.dtcx.com.br/api/bot/configure/${clientData.clientId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const config = res.data;
        setWelcomeMessage(config.welcomeMessage || '');
        setOptions(config.options || ['']);
        const responsesArray = Array.isArray(config.responses) ? config.responses : Object.values(config.responses || {});
        setResponses(responsesArray);
        setUnrecognizedMessage(config.unrecognizedMessage || '');
        setAskQuestionMessage(config.askQuestionMessage || '');
        setCloseChatMessage(config.closeChatMessage || '');
        setCloseChatTimeout(config.closeChatTimeout || 10); // default to 10 minutes
      } catch (error) {
        setError('Erro ao carregar a configuração do bot');
      }
    };

    const fetchQrCode = async () => {
      try {
        const res = await axios.get(`https://api.sagabot.dtcx.com.br/api/bot/qrcode/${clientData.clientId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setQrCode(res.data.qrCode);
      } catch (error) {
        setError('Erro ao carregar o QR Code');
      } finally {
        setLoadingQrCode(false);
      }
    };

    if (clientData && clientData.clientId && !dataLoaded) {
      fetchConfig();
      fetchQrCode();
      setDataLoaded(true);

      const intervalId = setInterval(() => {
        fetchConfig();
        fetchQrCode();
      }, 60000); // 60000ms = 1 minuto

      return () => clearInterval(intervalId); // Limpa o intervalo quando o componente é desmontado
    }
  }, [clientData?.clientId, token, dataLoaded]);

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleResponseChange = (index, value) => {
    const newResponses = [...responses];
    newResponses[index] = value;
    setResponses(newResponses);
  };

  const addOptionResponse = () => {
    setOptions([...options, '']);
    setResponses([...responses, '']);
  };

  const removeOptionResponse = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    const newResponses = responses.filter((_, i) => i !== index);
    setOptions(newOptions);
    setResponses(newResponses);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const responsesObject = options.reduce((acc, option, index) => {
      if (option && responses[index]) {
        acc[option] = responses[index];
      }
      return acc;
    }, {});

    try {
      await axios.post(
        `https://api.sagabot.dtcx.com.br/api/bot/configure`,
        {
          clientId: clientData.clientId,
          welcomeMessage,
          options,
          responses: responsesObject,
          unrecognizedMessage,
          askQuestionMessage,
          closeChatMessage,
          closeChatTimeout,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setSuccess('Bot configurado com sucesso');
    } catch (error) {
      setError('Erro ao configurar o bot');
    }
  };

  const closeModal = () => {
    setSuccess(null);
    setError(null);
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-6 text-center">Configuração do Bot</h2>
      {error && <Modal message={error} onClose={closeModal} />}
      {success && <Modal message={success} onClose={closeModal} />}
      <div className="mb-6 text-center">
        <h3 className="text-lg font-bold mb-2">Para ter o bot no seu WhatsApp, por favor escaneie o QR Code abaixo:</h3>
        {loadingQrCode ? (
          <p>Carregando QR Code...</p>
        ) : (
          qrCode && <img src={qrCode} alt="QR Code" className="mx-auto w-40 h-40 rounded-lg shadow-lg mb-4" />
        )}
      </div>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="mb-4">
          <label className="block font-bold mb-2">Mensagem de Boas-vindas:</label>
          <textarea
            className="w-full p-3 border rounded-md shadow-sm"
            value={welcomeMessage}
            onChange={(e) => setWelcomeMessage(e.target.value)}
          />
        </div>
        {options.map((option, index) => (
          <div key={index} className="mb-4 bg-white p-4 rounded-lg shadow-md">
            <h4 className="font-bold mb-2">Opção {index + 1}</h4>
            <input
              type="text"
              className="w-full p-2 border rounded-md mb-2 shadow-sm"
              placeholder={`Opção ${index + 1}`}
              value={option}
              onChange={(e) => handleOptionChange(index, e.target.value)}
            />
            <label className="block font-bold mb-2">Resposta:</label>
            <textarea
              className="w-full p-2 border rounded-md shadow-sm"
              placeholder={`Resposta ${index + 1}`}
              value={responses[index]}
              onChange={(e) => handleResponseChange(index, e.target.value)}
            />
            <button
              type="button"
              onClick={() => removeOptionResponse(index)}
              className="mt-2 bg-red-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-red-600 transition duration-200"
            >
              Remover
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={addOptionResponse}
          className="w-full bg-blue-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600 transition duration-200"
        >
          Adicionar Opção/Resposta
        </button>
        <div className="mb-4">
          <label className="block font-bold mb-2">Mensagem não reconhecida:</label>
          <textarea
            className="w-full p-3 border rounded-md shadow-sm"
            value={unrecognizedMessage}
            onChange={(e) => setUnrecognizedMessage(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2">Mensagem para pedir pergunta:</label>
          <textarea
            className="w-full p-3 border rounded-md shadow-sm"
            value={askQuestionMessage}
            onChange={(e) => setAskQuestionMessage(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2">Mensagem de encerramento do chat:</label>
          <textarea
            className="w-full p-3 border rounded-md shadow-sm"
            value={closeChatMessage}
            onChange={(e) => setCloseChatMessage(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2">Tempo de encerramento do chat (minutos):</label>
          <input
            type="number"
            className="w-full p-3 border rounded-md shadow-sm"
            value={closeChatTimeout}
            onChange={(e) => setCloseChatTimeout(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="w-full bg-green-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-green-600 transition duration-200"
        >
          Salvar Configuração
        </button>
      </form>
    </div>
  );
};

export default BotConfig;
