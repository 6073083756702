import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
} from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const Dashboard = () => {
  const { clientData, token, botStatus } = useOutletContext();
  const [recentMessages, setRecentMessages] = useState([]);
  const [stats, setStats] = useState(null);
  const [error, setError] = useState(null);

  const fetchRecentMessages = async () => {
    if (clientData && clientData.clientId) {
      try {
        const res = await axios.get(`https://api.sagabot.dtcx.com.br/api/client/messages`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setRecentMessages(res.data);
      } catch (err) {
        console.error('Error fetching recent messages:', err);
        setError('Error fetching recent messages: ' + err.message);
      }
    }
  };

  const fetchStats = async () => {
    if (clientData && clientData.clientId) {
      try {
        const res = await axios.get(`https://api.sagabot.dtcx.com.br/api/bot/stats/${clientData.clientId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setStats(res.data);
      } catch (err) {
        console.error('Error fetching stats:', err);
        setError('Error fetching stats: ' + err.message);
      }
    }
  };

  useEffect(() => {
    if (clientData && clientData.clientId && token) {
      //console.log('dashboard');
      fetchRecentMessages();
      fetchStats();
      const intervalId = setInterval(() => {
        fetchRecentMessages();
        fetchStats();
      }, 60000); // 60000ms = 1 minuto

      return () => clearInterval(intervalId); // Limpa o intervalo quando o componente é desmontado
    }
  }, [clientData?.clientId, token]);

  if (!clientData || !clientData.clientId) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  const messageTypeData = stats ? {
    labels: ['Mensagens de Usuário', 'Mensagens do Bot'],
    datasets: [
      {
        label: 'Tipos de Mensagem',
        data: [stats.messageTypeCount.fromUser, stats.messageTypeCount.fromBot],
        backgroundColor: ['rgba(255,99,132,0.6)', 'rgba(54,162,235,0.6)'],
        borderColor: ['rgba(255,99,132,1)', 'rgba(54,162,235,1)'],
        borderWidth: 1,
      }
    ]
  } : null;

  const messageCountData = stats ? {
    labels: Object.keys(stats.messageCountByDay),
    datasets: [
      {
        label: 'Mensagens por Dia',
        data: Object.values(stats.messageCountByDay),
        backgroundColor: 'rgba(75,192,192,0.6)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1
      }
    ]
  } : null;

  const formatPhoneNumber = (number) => number.replace('@c.us', '');

  return (
    <div>
      <h2 className="text-2xl font-bold mb-6">Dashboard</h2>
      <p>
        Status do Bot:{' '}
        <span className={`font-bold ${botStatus === 'Online' ? 'text-green-500' : 'text-red-500'}`}>
          {botStatus}
        </span>
      </p>
      <div className="flex">
        <div className="w-1/2 pr-4">
          <h3 className="text-xl font-bold mt-6 mb-4">Mensagens Recentes</h3>
          <ul className="list-disc pl-5">
            {recentMessages.map((message, index) => (
              <li key={index} className="mb-2 bg-white p-3 shadow-md rounded-lg">
                <p><strong>De:</strong> {formatPhoneNumber(message.from)}</p>
                <p><strong>Para:</strong> {formatPhoneNumber(message.to)}</p>
                <p><strong>Mensagem:</strong> {message.text}</p>
                <p><small><strong>Data:</strong> {new Date(message.timestamp).toLocaleString()}</small></p>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-1/2 pl-4">
          {stats && (
            <div>
              <h3 className="text-xl font-bold mt-6 mb-4">Estatísticas de Uso</h3>
              <div className="mb-4" style={{ height: '300px' }}>
                <Pie data={messageTypeData} options={{ responsive: true, maintainAspectRatio: false }} />
              </div>
              <div className="mb-4" style={{ height: '300px' }}>
                <Bar data={messageCountData} options={{ responsive: true, maintainAspectRatio: false }} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
