import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';

const AdminEmpresaAddGroup = () => {
  const { token } = useOutletContext();
  const [groupName, setGroupName] = useState('');
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'
  const [groups, setGroups] = useState([]);
  const [editGroupId, setEditGroupId] = useState(null);

  useEffect(() => {
    fetchGroups();
  }, [token]);

  const fetchGroups = async () => {
    try {
      const response = await axios.get('https://api.sagabot.dtcx.com.br/api/admin-empresa/grupos', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGroups(response.data);
    } catch (err) {
      console.error('Erro ao buscar grupos:', err);
      setMessage('Erro ao buscar grupos');
      setMessageType('error');
    }
  };

  const handleAddGroup = async () => {
    if (editGroupId) {
      handleUpdateGroup();
      return;
    }

    try {
      await axios.post(
        'https://api.sagabot.dtcx.com.br/api/admin-empresa/add-group',
        { nome: groupName },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMessage('Grupo adicionado com sucesso');
      setMessageType('success');
      setGroupName('');
      fetchGroups(); // Fetch groups again after adding a new group
    } catch (err) {
      console.error('Erro ao adicionar grupo:', err);
      setMessage('Erro ao adicionar grupo');
      setMessageType('error');
    }
  };

  const handleUpdateGroup = async () => {
    try {
      await axios.put(
        `https://api.sagabot.dtcx.com.br/api/admin-empresa/grupos/${editGroupId}`,
        { nome: groupName },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMessage('Grupo atualizado com sucesso');
      setMessageType('success');
      setGroupName('');
      setEditGroupId(null);
      fetchGroups(); // Fetch groups again after updating a group
    } catch (err) {
      console.error('Erro ao atualizar grupo:', err);
      setMessage('Erro ao atualizar grupo');
      setMessageType('error');
    }
  };

  const handleEditGroup = (group) => {
    setGroupName(group.nome);
    setEditGroupId(group.id);
  };

  const handleDeleteGroup = async (groupId) => {
    try {
      await axios.delete(`https://api.sagabot.dtcx.com.br/api/admin-empresa/grupos/${groupId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMessage('Grupo removido com sucesso');
      setMessageType('success');
      fetchGroups(); // Fetch groups again after deleting a group
    } catch (err) {
      console.error('Erro ao remover grupo:', err);
      setMessage('Erro ao remover grupo');
      setMessageType('error');
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Adicionar/Editar Grupo</h1>
      {message && (
        <div className={`mb-4 p-4 rounded ${messageType === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
          {message}
        </div>
      )}
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Nome do Grupo:</label>
        <input
          type="text"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <button
        onClick={handleAddGroup}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        {editGroupId ? 'Atualizar Grupo' : 'Adicionar Grupo'}
      </button>
      <h2 className="text-xl font-bold mt-6">Grupos Existentes</h2>
      <ul>
        {groups.map((group) => (
          <li key={group.id} className="mb-4 p-4 rounded bg-gray-100 flex justify-between items-center">
            {group.nome}
            <div>
              <button
                onClick={() => handleEditGroup(group)}
                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
              >
                Editar
              </button>
              <button
                onClick={() => handleDeleteGroup(group.id)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Remover
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminEmpresaAddGroup;
