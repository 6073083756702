import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaBars, FaTimes } from 'react-icons/fa';

const AdminEmpresaSidebarLayout = ({ token }) => {
  const [adminData, setAdminData] = useState(null);
  const [error, setError] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  const fetchAdminData = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/auth/admin-data', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setAdminData(res.data);
    } catch (err) {
      console.error('Error fetching admin data:', err);
      setError('Error fetching admin data: ' + err.message);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  useEffect(() => {
    fetchAdminData();
  }, [token]);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="flex h-screen overflow-hidden">
      <div className={`w-64 bg-gray-900 text-white p-5 flex flex-col justify-between h-full fixed transition-transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}>
        <div className="flex justify-between items-center mb-5">
          <h1 className="text-lg font-semibold">Admin Empresa</h1>
          <button onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="text-white focus:outline-none">
            {isSidebarOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
        <nav className="flex-grow">
          <ul className="space-y-2">
            <li>
              <Link to="/admin-empresa/dashboard" className="block py-2 px-3 rounded hover:bg-gray-700">
                Dashboard
              </Link>
            </li>
            <li>
              <Link to="/admin-empresa/add-user" className="block py-2 px-3 rounded hover:bg-gray-700">
                Adicionar Usuário
              </Link>
            </li>
            <li>
              <Link to="/admin-empresa/users" className="block py-2 px-3 rounded hover:bg-gray-700">
                Usuários Existentes
              </Link>
            </li>
            <li>
              <Link to="/admin-empresa/add-group" className="block py-2 px-3 rounded hover:bg-gray-700">
                Adicionar Grupo
              </Link>
            </li>
            {/*<li>
              <Link to="/admin-empresa/bot-config" className="block py-2 px-3 rounded hover:bg-gray-700">
                Configurações do BOT
              </Link> 
            </li>*/}
           {/* <li>
              <Link to="/admin-empresa/support" className="block py-2 px-3 rounded hover:bg-gray-700">
                Configurar FAQ
              </Link>
            </li> */}
          </ul>
        </nav>
        <button onClick={handleLogout} className="mt-10 w-full bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
          Sair
        </button>
      </div>
      <div className={`flex-1 ${isSidebarOpen ? 'ml-64' : 'ml-0'} p-10 overflow-y-auto bg-gray-100 transition-all`}>
        <Outlet context={{ adminData, token }} />
      </div>
    </div>
  );
};

export default AdminEmpresaSidebarLayout;
