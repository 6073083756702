import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
} from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import ConfirmModal from './ConfirmModal';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const AdminDashboard = ({ token }) => {
  const [dashboardData, setDashboardData] = useState([]);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);

  const fetchDashboardData = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/admin/dashboard-data', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDashboardData(res.data);
      //console.log("Dashboard data fetched: ", res.data);
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError('Error fetching dashboard data: ' + err.message);
    }
  };

  const fetchClientsList = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/admin/clients', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDashboardData(prevData =>
        prevData.map(empresa => ({
          ...empresa,
          users: res.data.filter(user => user.empresaId === empresa.empresaId)
        }))
      );
    } catch (err) {
      console.error('Error fetching clients list:', err);
      setError('Error fetching clients list: ' + err.message);
    }
  };

  const handleDeleteClient = async (clientId) => {
    try {
      await axios.delete(`https://api.sagabot.dtcx.com.br/api/admin/client/${clientId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchDashboardData();
      fetchClientsList();
    } catch (err) {
      console.error('Error deleting client:', err);
      setError('Error deleting client: ' + err.message);
    }
  };

  const confirmDeleteClient = (clientId) => {
    setClientToDelete(clientId);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    handleDeleteClient(clientToDelete);
    setIsModalOpen(false);
    setClientToDelete(null);
  };

  const handleCancelDelete = () => {
    setIsModalOpen(false);
    setClientToDelete(null);
  };

  useEffect(() => {
    fetchDashboardData();
    fetchClientsList();
  }, [token]);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-6">Dashboard Administrativo</h2>
      {dashboardData.length > 0 ? (
        dashboardData.map((empresa) => (
          <div key={empresa.empresaId} className="mb-6">
            <h3 className="text-xl font-bold mb-4">{empresa.empresaNome}</h3>
            <div className="bg-white p-4 rounded shadow mb-4">
              <h4 className="text-lg font-bold mb-2">Estatísticas de Clientes</h4>
              <p>Total de Clientes: {empresa.totalClients}</p>
              <p>Clientes Online: {empresa.onlineClients}</p>
              <p>Clientes Offline: {empresa.offlineClients}</p>
              <div style={{ height: '300px' }}>
                <Pie
                  data={{
                    labels: ['Online', 'Offline'],
                    datasets: [
                      {
                        data: [empresa.onlineClients, empresa.offlineClients],
                        backgroundColor: ['rgba(75,192,192,0.6)', 'rgba(255,99,132,0.6)'],
                        borderColor: ['rgba(75,192,192,1)', 'rgba(255,99,132,1)'],
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={{ responsive: true, maintainAspectRatio: false }}
                />
              </div>
            </div>
            <div className="bg-white p-4 rounded shadow">
              <h4 className="text-lg font-bold mb-2">Lista de Clientes</h4>
              <ul className="list-disc pl-5">
                {empresa.users.length === 0 ? (
                  <li className="mb-2">Nenhum cliente encontrado.</li>
                ) : (
                  empresa.users.map((user) => (
                    <li key={user.id} className="mb-2">
                      <p className="font-bold">{user.clientId}</p>
                      <p className={user.status === 'Online' ? 'text-green-500' : 'text-red-500'}>
                        {user.status}
                      </p>
                      <button
                        onClick={() => confirmDeleteClient(user.clientId)}
                        className="bg-red-500 text-white px-4 py-2 rounded-md"
                      >
                        Remover
                      </button>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
        ))
      ) : (
        <p>Carregando dados...</p>
      )}
      <ConfirmModal
        isOpen={isModalOpen}
        title="Confirmação de Deleção"
        message="Tem certeza que deseja deletar este cliente?"
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </div>
  );
};

export default AdminDashboard;
