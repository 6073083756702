import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfirmModal from './ConfirmModal';

const UserManagement = ({ token }) => {
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [newUser, setNewUser] = useState({ clientId: '', password: '', role: 'client', grupo_id: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    fetchUsers();
    fetchGroups();
  }, [token]);

  const fetchUsers = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/usuarios', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setUsers(res.data);
    } catch (err) {
      console.error('Error fetching users:', err);
    }
  };

  const fetchGroups = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/grupos', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setGroups(res.data);
    } catch (err) {
      console.error('Error fetching groups:', err);
    }
  };

  const getGroupName = (grupo_id) => {
    const group = groups.find(g => g.id === grupo_id);
    return group ? group.nome : grupo_id;
  };

  const handleAddUser = async () => {
    try {
      const res = await axios.post('https://api.sagabot.dtcx.com.br/api/usuarios', newUser, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setUsers([...users, res.data]);
      setNewUser({ clientId: '', password: '', role: 'client', grupo_id: '' });
    } catch (err) {
      console.error('Error adding user:', err);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`https://api.sagabot.dtcx.com.br/api/usuarios/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setUsers(users.filter((user) => user.id !== userId));
    } catch (err) {
      console.error('Error deleting user:', err);
    }
  };

  const openModal = (user) => {
    setUserToDelete(user);
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    handleDeleteUser(userToDelete.id);
    setIsModalOpen(false);
    setUserToDelete(null);
  };

  const cancelDelete = () => {
    setIsModalOpen(false);
    setUserToDelete(null);
  };

  return (
    <div>
      <h2 className="text-2xl mb-4">Gerenciar Usuários</h2>
      <div className="mb-4">
        <input
          type="text"
          value={newUser.clientId}
          onChange={(e) => setNewUser({ ...newUser, clientId: e.target.value })}
          className="border p-2 mr-2"
          placeholder="Client ID"
        />
        <input
          type="password"
          value={newUser.password}
          onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
          className="border p-2 mr-2"
          placeholder="Senha"
        />
        <select
          value={newUser.grupo_id}
          onChange={(e) => setNewUser({ ...newUser, grupo_id: e.target.value })}
          className="border p-2 mr-2"
        >
          <option value="">Selecione o Grupo</option>
          {groups.map((group) => (
            <option key={group.id} value={group.id}>
              {group.nome}
            </option>
          ))}
        </select>
        <button onClick={handleAddUser} className="bg-blue-500 text-white p-2 rounded">
          Adicionar Usuário
        </button>
      </div>
      <ul>
        {users.map((user) => (
          <li key={user.id} className="mb-2 border p-2 flex justify-between items-center">
            {user.clientId} (Grupo: {getGroupName(user.grupo_id)})
            <button onClick={() => openModal(user)} className="bg-red-500 text-white p-2 rounded">
              Remover
            </button>
          </li>
        ))}
      </ul>
      <ConfirmModal
        isOpen={isModalOpen}
        title="Confirmar Exclusão"
        message={`Tem certeza que deseja excluir o usuário ${userToDelete?.clientId}?`}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      />
    </div>
  );
};

export default UserManagement;
