import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfirmModal from './ConfirmModal';

const GroupManagement = ({ token }) => {
  const [groups, setGroups] = useState([]);
  const [newGroup, setNewGroup] = useState({ nome: '', empresa_id: '' });
  const [empresas, setEmpresas] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);

  useEffect(() => {
    fetchGroups();
    fetchEmpresas();
  }, [token]);

  const fetchGroups = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/grupos', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setGroups(res.data);
    } catch (err) {
      console.error('Error fetching groups:', err);
    }
  };

  const fetchEmpresas = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/empresas', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setEmpresas(res.data);
    } catch (err) {
      console.error('Error fetching companies:', err);
    }
  };

  const getEmpresaNome = (empresa_id) => {
    const empresa = empresas.find(e => e.id === empresa_id);
    return empresa ? empresa.nome : empresa_id;
  };

  const handleAddGroup = async () => {
    try {
      const res = await axios.post('https://api.sagabot.dtcx.com.br/api/grupos', newGroup, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setGroups([...groups, res.data]);
      setNewGroup({ nome: '', empresa_id: '' });
    } catch (err) {
      console.error('Error adding group:', err);
    }
  };

  const handleDeleteGroup = async (groupId) => {
    try {
      await axios.delete(`https://api.sagabot.dtcx.com.br/api/grupos/${groupId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setGroups(groups.filter((group) => group.id !== groupId));
    } catch (err) {
      console.error('Error deleting group:', err);
    }
  };

  const openModal = (group) => {
    setGroupToDelete(group);
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    handleDeleteGroup(groupToDelete.id);
    setIsModalOpen(false);
    setGroupToDelete(null);
  };

  const cancelDelete = () => {
    setIsModalOpen(false);
    setGroupToDelete(null);
  };

  return (
    <div>
      <h2 className="text-2xl mb-4">Gerenciar Grupos</h2>
      <div className="mb-4">
        <input
          type="text"
          value={newGroup.nome}
          onChange={(e) => setNewGroup({ ...newGroup, nome: e.target.value })}
          className="border p-2 mr-2"
          placeholder="Nome do Grupo"
        />
        <select
          value={newGroup.empresa_id}
          onChange={(e) => setNewGroup({ ...newGroup, empresa_id: e.target.value })}
          className="border p-2 mr-2"
        >
          <option value="">Selecione a Empresa</option>
          {empresas.map((empresa) => (
            <option key={empresa.id} value={empresa.id}>
              {empresa.nome}
            </option>
          ))}
        </select>
        <button onClick={handleAddGroup} className="bg-blue-500 text-white p-2 rounded">
          Adicionar Grupo
        </button>
      </div>
      <ul>
        {groups.map((group) => (
          <li key={group.id} className="mb-2 border p-2 flex justify-between items-center">
            {group.nome} (Empresa: {getEmpresaNome(group.empresa_id)})
            <button onClick={() => openModal(group)} className="bg-red-500 text-white p-2 rounded">
              Remover
            </button>
          </li>
        ))}
      </ul>
      <ConfirmModal
        isOpen={isModalOpen}
        title="Confirmar Exclusão"
        message={`Tem certeza que deseja excluir o grupo ${groupToDelete?.nome}?`}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      />
    </div>
  );
};

export default GroupManagement;
