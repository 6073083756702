import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfirmModal from './ConfirmModal';

const CompanyManagement = ({ token }) => {
  const [companies, setCompanies] = useState([]);
  const [newCompany, setNewCompany] = useState({ nome: '', clientId: '', password: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyToDelete, setCompanyToDelete] = useState(null);

  useEffect(() => {
    fetchCompanies();
  }, [token]);

  const fetchCompanies = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/empresas', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setCompanies(res.data);
    } catch (err) {
      console.error('Error fetching companies:', err);
    }
  };

  const handleAddCompany = async () => {
    try {
      const res = await axios.post(
        'https://api.sagabot.dtcx.com.br/api/empresas',
        newCompany,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setCompanies([...companies, res.data]);
      setNewCompany({ nome: '', clientId: '', password: '' });
    } catch (err) {
      console.error('Error adding company:', err);
    }
  };

  const handleDeleteCompany = async (companyId) => {
    try {
      await axios.delete(`https://api.sagabot.dtcx.com.br/api/empresas/${companyId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setCompanies(companies.filter((company) => company.id !== companyId));
    } catch (err) {
      console.error('Error deleting company:', err);
    }
  };

  const openModal = (company) => {
    setCompanyToDelete(company);
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    handleDeleteCompany(companyToDelete.id);
    setIsModalOpen(false);
    setCompanyToDelete(null);
  };

  const cancelDelete = () => {
    setIsModalOpen(false);
    setCompanyToDelete(null);
  };

  return (
    <div>
      <h2 className="text-2xl mb-4">Gerenciar Empresas</h2>
      <div className="mb-4">
        <input
          type="text"
          value={newCompany.nome}
          onChange={(e) => setNewCompany({ ...newCompany, nome: e.target.value })}
          className="border p-2 mr-2"
          placeholder="Nome da Empresa"
        />
        <input
          type="text"
          value={newCompany.clientId}
          onChange={(e) => setNewCompany({ ...newCompany, clientId: e.target.value })}
          className="border p-2 mr-2"
          placeholder="ID do Usuário Admin"
        />
        <input
          type="password"
          value={newCompany.password}
          onChange={(e) => setNewCompany({ ...newCompany, password: e.target.value })}
          className="border p-2 mr-2"
          placeholder="Senha do Usuário Admin"
        />
        <button onClick={handleAddCompany} className="bg-blue-500 text-white p-2 rounded">
          Adicionar Empresa
        </button>
      </div>
      <ul>
        {companies.map((company) => (
          <li key={company.id} className="mb-2 border p-2 flex justify-between items-center">
            {company.nome}
            <button onClick={() => openModal(company)} className="bg-red-500 text-white p-2 rounded">
              Remover
            </button>
          </li>
        ))}
      </ul>
      <ConfirmModal
        isOpen={isModalOpen}
        title="Confirmar Exclusão"
        message={`Tem certeza que deseja excluir a empresa ${companyToDelete?.nome}?`}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      />
    </div>
  );
};

export default CompanyManagement;
