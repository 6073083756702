import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';

const AdminEmpresaSupport = () => {
  const { token } = useOutletContext();
  const [faqs, setFaqs] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [newAnswer, setNewAnswer] = useState('');
  const [newKeywords, setNewKeywords] = useState('');
  const [error, setError] = useState('');

  const fetchFaqs = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/admin-empresa/faq', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFaqs(res.data);
    } catch (err) {
      console.error('Error fetching FAQs:', err);
      setError('Error fetching FAQs: ' + err.message);
    }
  };

  const handleAddFaq = async () => {
    try {
      await axios.post(
        'https://api.sagabot.dtcx.com.br/api/admin-empresa/faq/add',
        { question: newQuestion, answer: newAnswer, keywords: newKeywords.split(',') },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchFaqs();
      setNewQuestion('');
      setNewAnswer('');
      setNewKeywords('');
    } catch (err) {
      console.error('Error adding FAQ:', err);
      setError('Error adding FAQ: ' + err.message);
    }
  };

  const handleDeleteFaq = async (id) => {
    try {
      await axios.delete(`https://api.sagabot.dtcx.com.br/api/admin-empresa/faq/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchFaqs();
    } catch (err) {
      console.error('Error deleting FAQ:', err);
      setError('Error deleting FAQ: ' + err.message);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, [token]);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Gerenciamento de FAQs</h1>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-4">Adicionar Nova FAQ</h2>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Pergunta:</label>
          <input
            type="text"
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Resposta:</label>
          <input
            type="text"
            value={newAnswer}
            onChange={(e) => setNewAnswer(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Palavras-chave (separadas por vírgula):</label>
          <input
            type="text"
            value={newKeywords}
            onChange={(e) => setNewKeywords(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <button
          onClick={handleAddFaq}
          className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
        >
          Adicionar FAQ
        </button>
      </div>
      <h2 className="text-xl font-bold mb-4">FAQs Existentes</h2>
      {faqs.length > 0 ? (
        faqs.map((faq) => (
          <div key={faq._id} className="mb-4">
            <h3 className="text-lg font-bold">{faq.question}</h3>
            <p>{faq.answer}</p>
            <p><strong>Palavras-chave:</strong> {faq.keywords.join(', ')}</p>
            <button
              onClick={() => handleDeleteFaq(faq._id)}
              className="bg-red-500 text-white px-4 py-2 rounded-md mt-2"
            >
              Remover
            </button>
          </div>
        ))
      ) : (
        <p>Nenhuma FAQ encontrada.</p>
      )}
    </div>
  );
};

export default AdminEmpresaSupport;
