import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';

const AdminEmpresaBotConfig = () => {
  const { token } = useOutletContext();
  const [botConfig, setBotConfig] = useState(null);
  const [options, setOptions] = useState([]);
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [unrecognizedMessage, setUnrecognizedMessage] = useState('');
  const [newOption, setNewOption] = useState('');
  const [newResponse, setNewResponse] = useState('');

  const fetchBotConfig = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/admin-empresa/bot/config', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBotConfig(res.data);
      setOptions(res.data.options);
      setWelcomeMessage(res.data.welcomeMessage);
      setUnrecognizedMessage(res.data.unrecognizedMessage);
    } catch (err) {
      console.error('Error fetching bot config:', err);
    }
  };

  const handleSaveConfig = async () => {
    try {
      await axios.post(
        'https://api.sagabot.dtcx.com.br/api/admin-empresa/bot/config',
        { clientId: botConfig.clientId, options: JSON.stringify(options), welcomeMessage, unrecognizedMessage },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchBotConfig();
    } catch (err) {
      console.error('Error saving bot config:', err);
    }
  };

  const handleAddOption = () => {
    setOptions([...options, { option: newOption, response: newResponse }]);
    setNewOption('');
    setNewResponse('');
  };

  const handleRemoveOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  useEffect(() => {
    fetchBotConfig();
  }, [token]);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Configurações do BOT</h1>
      {botConfig ? (
        <div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Mensagem de Boas-vindas:</label>
            <input
              type="text"
              value={welcomeMessage}
              onChange={(e) => setWelcomeMessage(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Mensagem Não Reconhecida:</label>
            <input
              type="text"
              value={unrecognizedMessage}
              onChange={(e) => setUnrecognizedMessage(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-6">
            <h2 className="text-xl font-bold mb-4">Opções e Respostas</h2>
            {options.map((option, index) => (
              <div key={index} className="mb-4">
                <input
                  type="text"
                  value={option.option}
                  onChange={(e) => {
                    const newOptions = [...options];
                    newOptions[index].option = e.target.value;
                    setOptions(newOptions);
                  }}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                />
                <input
                  type="text"
                  value={option.response}
                  onChange={(e) => {
                    const newOptions = [...options];
                    newOptions[index].response = e.target.value;
                    setOptions(newOptions);
                  }}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <button
                  onClick={() => handleRemoveOption(index)}
                  className="bg-red-500 text-white px-4 py-2 rounded-md mt-2"
                >
                  Remover
                </button>
              </div>
            ))}
            <div className="flex mb-4">
              <input
                type="text"
                placeholder="Nova Opção"
                value={newOption}
                onChange={(e) => setNewOption(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2"
              />
              <input
                type="text"
                placeholder="Nova Resposta"
                value={newResponse}
                onChange={(e) => setNewResponse(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <button
                onClick={handleAddOption}
                className="bg-blue-500 text-white px-4 py-2 rounded-md ml-2"
              >
                Adicionar
              </button>
            </div>
          </div>
          <button
            onClick={handleSaveConfig}
            className="bg-green-500 text-white font-bold py-2 px-4 rounded"
          >
            Salvar Configuração
          </button>
        </div>
      ) : (
        <p>Carregando configuração...</p>
      )}
    </div>
  );
};

export default AdminEmpresaBotConfig;
