import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './index.css';
import Login from './components/Login';
import AdminPanel from './components/AdminPanel';
import AdminDashboard from './components/AdminDashboard';
import ClientPanel from './components/ClientPanel';
import Dashboard from './components/Dashboard';
import BotConfig from './components/BotConfig';
import Analytics from './components/Analytics';
import SidebarLayout from './components/SidebarLayout';
import AdminSidebarLayout from './components/AdminSidebarLayout';
import AdminClients from './components/AdminClients';
import AdminSettings from './components/AdminSettings';
import AdminSupport from './components/AdminSupport';
import AdminBotConfig from './components/AdminBotConfig';
import CompanyManagement from './components/CompanyManagement';
import GroupManagement from './components/GroupManagement';
import UserManagement from './components/UserManagement';
import AdminEmpresaSidebarLayout from './components/AdminEmpresaSidebarLayout';
import AdminEmpresaDashboard from './components/AdminEmpresaDashboard';
import AdminEmpresaAddUser from './components/AdminEmpresaAddUser';
import AdminEmpresaUsers from './components/AdminEmpresaUsers';
import AdminEmpresaBotConfig from './components/AdminEmpresaBotConfig';
import AdminEmpresaSupport from './components/AdminEmpresaSupport';
import AdminEmpresaAddGroup from './components/AdminEmpresaAddGroup';
import ClientSupport from './components/ClientSupport'; // Import the new ClientSupport component

const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [role, setRole] = useState('');

  useEffect(() => {
    if (token) {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      setRole(decodedToken.user.role);
    }
  }, [token]);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login setToken={setToken} />} />
        <Route
          path="/admin"
          element={role === 'admin' ? <AdminSidebarLayout token={token} /> : <Navigate to="/login" />}
        >
          <Route index element={<Navigate to="/admin/dashboard" />} />
          <Route path="dashboard" element={<AdminDashboard token={token} />} />
          <Route path="add-client" element={<AdminPanel token={token} />} />
          <Route path="clients" element={<AdminClients token={token} />} />
          <Route path="settings" element={<AdminSettings token={token} />} />
          <Route path="bot-config" element={<AdminBotConfig token={token} />} />
          <Route path="support" element={<AdminSupport token={token} />} />
          <Route path="company-management" element={<CompanyManagement token={token} />} />
          <Route path="group-management" element={<GroupManagement token={token} />} />
          <Route path="user-management" element={<UserManagement token={token} />} />
        </Route>
        <Route
          path="/admin-empresa"
          element={role === 'admin_empresa' ? <AdminEmpresaSidebarLayout token={token} /> : <Navigate to="/login" />}
        >
          <Route index element={<Navigate to="/admin-empresa/dashboard" />} />
          <Route path="dashboard" element={<AdminEmpresaDashboard token={token} />} />
          <Route path="add-user" element={<AdminEmpresaAddUser token={token} />} />
          <Route path="users" element={<AdminEmpresaUsers token={token} />} />
          <Route path="add-group" element={<AdminEmpresaAddGroup token={token} />} />
          <Route path="bot-config" element={<AdminEmpresaBotConfig token={token} />} />
          <Route path="support" element={<AdminEmpresaSupport token={token} />} />
        </Route>
        <Route
          path="/client"
          element={role === 'client' ? <SidebarLayout token={token} /> : <Navigate to="/login" />}
        >
          <Route index element={<Navigate to="/client/dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="config" element={<BotConfig token={token} />} />
          <Route path="analytics" element={<Analytics token={token} />} />
          <Route path="support" element={<ClientSupport token={token} />} /> {/* Update this route */}
        </Route>
        <Route
          path="/"
          element={token ? (role === 'admin' ? <Navigate to="/admin/dashboard" /> : role === 'admin_empresa' ? <Navigate to="/admin-empresa/dashboard" /> : <Navigate to="/client/dashboard" />) : <Navigate to="/login" />}
        />
      </Routes>
    </Router>
  );
};

export default App;
