import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';

const AdminEmpresaDashboard = () => {
  const { token } = useOutletContext();
  const [dashboardData, setDashboardData] = useState([]);
  const [error, setError] = useState(null);

  const fetchDashboardData = async () => {
    try {
      const res = await axios.get('https://api.sagabot.dtcx.com.br/api/admin-empresa/dashboard-data', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDashboardData(res.data);
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError('Error fetching dashboard data: ' + err.message);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, [token]);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-6">Dashboard Administrativo da Empresa</h2>
      {dashboardData.length > 0 ? (
        dashboardData.map((empresa) => (
          <div key={empresa.empresaId} className="mb-6">
            <h3 className="text-xl font-bold mb-4">{empresa.empresaNome}</h3>
            <div className="bg-white p-4 rounded shadow mb-4">
              <h4 className="text-lg font-bold mb-2">Estatísticas de Usuários</h4>
              <p>Total de Usuários: {empresa.totalUsers}</p>
              <p>Usuários Online: {empresa.onlineUsers}</p>
              <p>Usuários Offline: {empresa.offlineUsers}</p>
            </div>
            <div className="bg-white p-4 rounded shadow">
              <h4 className="text-lg font-bold mb-2">Lista de Usuários</h4>
              <ul className="list-disc pl-5">
                {empresa.users.length === 0 ? (
                  <li className="mb-2">Nenhum usuário encontrado.</li>
                ) : (
                  empresa.users.map((user) => (
                    <li key={user.id} className="mb-2">
                      <p className="font-bold">{user.clientId}</p>
                      <p className={user.status === 'Online' ? 'text-green-500' : 'text-red-500'}>
                        {user.status}
                      </p>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
        ))
      ) : (
        <p>Carregando dados...</p>
      )}
    </div>
  );
};

export default AdminEmpresaDashboard;
